import React from 'react';
import Markdown from 'markdown-to-jsx';
import { graphql } from 'gatsby';
import { Grid } from 'semantic-ui-react';
import Helmet from 'react-helmet';
import { PageLayout, CardNavigation, HeroElement, About, ImageCollection, Reviews, Contact,  } from '@fishrmn/fishrmn-components';
import Layout from '../components/Layout';
import HeroImage from '../data/ezgif.com-webp-to-png (1) (1).png'

export default class extends React.PureComponent {

  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Home</title>
          <meta name="description" content="Hudson’s Cafe proudly serves American food to the greater Playa Vista community." />
        </Helmet>
        <PageLayout
          hero={ 
              <HeroElement
                header={ data.businessName }
                tagline={null}
                images={ [HeroImage] }
                showMap={ false }
                ctas={ [{
                  link: "https://ordering.chownow.com/order/2274/locations",
                  text: "Order Online",
                  internal: false,
                  className: "chownow-order-online"
                }] }
                ctaInverted={ true }
                ctaColor={ "white" }
                containerColor={ "primary" }
                textColor={ "white" }
                showMultiple={ true }
                overlay={ true }
                fullWidth={ true }
                parallax={ false }
                containerAs={ "none" }
                height={ "100vh" }
                gutter={ false }
              />
           }
          subfooter={ false }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={ "middle" }
            textAlign={ "center" }
          >
            <Grid.Column width={ 16 }>
              <About
                content={ <Markdown>{data.aboutMarkdown}</Markdown> }
                header={ `Welcome to ${data.businessName}` }
                centerContent={ true }
                headerAs={ "h1" }
                withContainer={ true }
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={ "middle" }
            textAlign={ "center" }
          >
            <Grid.Column width={ 16 }>
              <ImageCollection
                as={ "gallery" }
                centered={ false }
                cutUnevenRows={ true }
                disable={ "none" }
                header={ "Gallery" }
                images={ data.galleryImages.map(({url}) => url) }
                numberOfRowDisplayItems={ 4 }
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={ "middle" }
            textAlign={ "left" }
          >
            <Grid.Column width={ 16 }>
              <Reviews
                reviews={ data.reviews }
                as={ "horizontalItem" }
                buttonBasic={ false }
                buttonSize={ "medium" }
                cardInlinePhoto={ "left" }
                centerHeader={ true }
                header={ "Reviews" }
                imageCircular={ false }
                imageSize={ "small" }
                itemButtonFloat={ "left" }
                itemCenterContent={ false }
                itemsDivided={ false }
                itemsPerRow={ 3 }
                linkAs={ "none" }
                onlyEvenRows={ true }
                showImage={ true }
                verticalItemAlign={ "center" }
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable
            className="component-section-container"
          >
            <Grid.Column width={ 16 }>
              <Contact
                phoneNumber={ data.phone }
                address={{
                  street:data.address.street,
                  city:data.address.city,
                  state:data.address.state,
                  zip:data.address.zip,
                }}
                as={ "text" }
                buttonColor={ "secondary" }
                header={ "Contact" }
                image={ "" }
                showMap={ true }
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          ctas {
            orderOnline {
              link 
              text 
              internal 
            }
            phone {
              link 
              text 
              internal 
            }
          }
          businessName 
          aboutMarkdown 
          reviews {
            name 
            content 
            link 
          }
          phone 
          address {
            street 
            city 
            state 
            zip 
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url 
          }
          heroImages {
            url 
          }
        }
      }
    }
  }
`;